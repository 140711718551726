import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Divider from "@mui/material/Divider";
import { useTranslation } from "react-i18next";

const TabComponent =({tabs, contents})=> {
    const [value, setValue] = React.useState(0);
    const [content, setContent] = useState("");
    const [t] = useTranslation("global");

    const handleChange = (event, newValue) => {
      setValue(newValue);
      setContent(contents[newValue]); 

    };
    useEffect(() => {
        let _content = <></>;
        contents ? setContent(contents[value]) : setContent(_content); 
    }, [contents]);
    
return (
    <>
    <Tabs value={value} onChange={handleChange}>
        {
            tabs.map((item, index)=>
                <Tab
                iconPosition="end"
                label={t(item)}
                style={{ 
                    width: "146px",
                    margin: "5px 5px 5px 5px"
                }}
                key={index}
              />
        )}
      </Tabs>
      <Divider
        sx={{
          background: "#DFE3E7",
        }}
      />
      <div style={{ paddingTop: "30px" }}>
          {content}
      </div>
    </>
  );
}
export default TabComponent; 