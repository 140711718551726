import Sesion from "../../services/sesion";
import React, {Fragment, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import axiosClient from "../../services/axiosClient";
import {SnackBar} from "../../components/commons/alert";
import BasicTable from "../../components/commons/basicTable";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { Button, Stack } from "@mui/material";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { ExportExcel } from "../../utils/exportExcel";
import { ImportExcel} from "../../utils/importExcel";

export default function Operations() {
  const [data, setData] = useState([]);
  const [dataToDownload, setDataToDownload] = useState([]);
  const [columns, setColumns] = useState([]);
  const [columnsToDownload, setColumnsToDownload] = useState([]);
  const [t] = useTranslation("global");

  const getMerchant = () => {
    axiosClient.get(`merchants/pending-validation`, {})
    .then(response => {
      setInfo(response);
    })
    .catch(_err => {
      SnackBar(t("General.Message.ErrorService"), "error");
    });
  };

  const setInfo = (response) => {
    const trans = "Operations.";
    let col = [];
    if (response.data['columns']) {
      for (let value of Object.values(response.data.columns)) {
        col.push(trans.concat(value));
      }
    }
    setColumns(col);
    setColumnsToDownload([
      "Merchant ID",
      "Nombre",
      "Fecha de nacimiento",
      "Nacionalidad",
      "Persona",
      "Tipo de persona"
    ]);
    if(response.data.results){
      setData(response.data.results);
      setDataToDownload(response.data.results_to_download);
    }
  }

  const createAction = (row, i) => {
    return (
      <TableCell key={i} align="center">
        <Button
          disabled
          style={{
            borderRadius: "10px",
            textTransform: "none",
            backgroundColor: "#FFEEB5",
            color: "#7D6000",
            borderColor: "white",
            fontWeight: "bold",
            marginTop: "10px",
          }}
          variant="outlined"
          startIcon={<AccessTimeIcon />}
        >
            {t(`Status.${row.Status}`)}
        </Button>
      </TableCell>
    );
  };

  const table = (action, row, i) => {
    return (
      <TableRow key={row.id}>
        {Object.keys(row).map((cell, index) => {
          if (index + 1 === Object.keys(row).length) {
            return action(row, i);
          } else {
            return (
              <TableCell key={`table-${index}`} align="center">
                {row[cell]}
              </TableCell>
            );
          }
        })}
      </TableRow>
    )
  }

  useEffect(() => {
    getMerchant();
  }, []);

  return (
    <div className={"Drawer"}>
      <Sesion screen={"Operations"}/>
      <Stack direction="row" spacing={2} justifyContent="right">
        <ExportExcel
          fileType={"csv"}
          fileName={"Operaciones"}
          data={dataToDownload}
          columns={columnsToDownload}
        />
        <ImportExcel
          fileType={"csv"}
          columns={columns}
          refetch={getMerchant}
        />
      </Stack>
      <Fragment>
        <BasicTable
          dataTable={data}
          dataColumns={columns}
          action={createAction}
          table={table}
        />
      </Fragment>
    </div>
  );
}

