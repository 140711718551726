import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { useTranslation } from "react-i18next";
import { SnackBar } from "../components/commons/alert";
import axiosClient from "../services/axiosClient";

export const ImportExcel = ({ fileType, columns, refetch }) => {
  const [t] = useTranslation("global");
  const [isUpload, setIsUpload] = useState(false);
  const csv = "csv";
  const validType = [csv];

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
  
      fileReader.onload = () => {
        resolve(fileReader.result.replace(`data:text/${validType};base64,`, ""));
      };
  
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const importFile = async (event) => {
    try {
      const file = event.target.files[0];
      if(event.target.files[0] !== undefined){
        const base64 = await convertBase64(file);
        axiosClient.post(`validate_merchant_users`, {
            file: base64
          },
          {
            headers: {
              'Content-Type': 'application/json',
            }
          }
        ).then(response => {
          SnackBar(response.data, "success");
          setIsUpload(true);
        })
        .catch(_err => {
          SnackBar(t("General.Message.ErrorService"), "error");
        });
      }
    } catch (error) {
      SnackBar(t("Download.Message.Error") + error, "error");
    }
  };
  
  useEffect(() => {
    if (isUpload && refetch)  {
      refetch();
    }
  }, [isUpload]);

  return (
    <Button
      component="label"
      variant="outlined"
      startIcon={
        <ArrowUpwardIcon/>
      }
      onChange={(e) => importFile(e)}
      className="action-button-outlined-secondary"
    >
      {t("Buttons.Import", { fileType: fileType })}
      <input hidden accept={"."+ fileType} multiple type="file" />
    </Button>
  );
};
