import { useSelector } from "react-redux";

export default function PermissionsGate({ children, scopes = [] }) {
  const metadataredux = useSelector((state) => state);
  const {
    user_metadata: { user_rol },
  } = metadataredux;

  var permissionGranted = false;

  try {
    permissionGranted = scopes.split(".").reduce((obj, i) => obj[i], user_rol);
  } catch (e) {}

  if (!permissionGranted) return <></>;

  return <>{children}</>;
}
