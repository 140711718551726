import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./assets/redux/store";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import global_es from "./assets/translations/es/global.json";
import global_en from "./assets/translations/en/global.json";
import Routing from "./Routing";
import "@fontsource/roboto/latin-400.css";
import "@fontsource/roboto/latin-500.css";
import "./index.css";
import { ThemeProvider } from "@mui/material/styles";
import Theme from "./assets/themes/Theme";
import { AuthProvider } from "./context/AuthProvider";

i18next.init({
  lng: "es",
  resources: {
    es: {
      global: global_es,
    },
    en: {
      global: global_en,
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
    <AuthProvider>
        <Provider store={store}>
          <ThemeProvider theme={Theme}>
            <Routing>
              <App />
            </Routing>
          </ThemeProvider>
        </Provider>
      </AuthProvider>
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
