import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom"; 
import SideBar from "./layout/sidebar/sideBar";
import Merchants from "./pages/Merchants/Merchants";
import Insight from "./pages/Insight/Insight";  
import Operations from "./pages/Operations/Operations";
import PersistLogin from "./components/PersistLogin"; 
import RequireAuth from "./components/RequireAuth";
import SignIn from "./pages/SignIn/SignIn";

const Routing = () => {
  return (
    <Router>
      <SideBar />
      <Routes>
        <Route exact path="/SignIn" element={<SignIn />} />
        <Route element={<PersistLogin />}>
          <Route element={<RequireAuth />}>
            <Route exact path="/index.html" element={<Insight />} />
            <Route path="/" element={<Navigate replace to="/Insight" />} /> 
            <Route exact path="/Merchants" element={<Merchants />} />
            <Route exact path="/Operations" element={<Operations />} />
            <Route exact path="/Insight" element={<Insight />} />
            <Route exact path="/SignIn" element={<SignIn />} />
          </Route>
        </Route>
      </Routes>
    </Router>
  );
};

export default Routing;
