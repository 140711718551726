import React, { useState, useEffect } from "react";
import SimpleCard from "../../components/commons/card";
import TabComponent from "../../components/commons/tabs";
import RecordDetails from "../../components/commons/record";


const proceedings = (sections, config) => {
    return (
      <RecordDetails
        sections={sections}
        config={config}
      />
    );
  };

const Details = ({ title, subtitle, data }) => {
  const [proceedingsTab, setProceedingsTab] = useState();

  useEffect(() => {
    setProceedingsTab(proceedings(data.proceedings.sections, data.proceedings.config))
  }, [data]);

  return (
    <>
      <SimpleCard title={title} subtitle={subtitle} />
      <TabComponent tabs={data.proceedings.tabs} contents={[proceedingsTab]} />
    </>
  );
};
export default Details;
