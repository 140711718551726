const user = {
  user_info: {
    id: "23e4800d-d81f-4025-b19f-8f056e56c6aa",
    name: "Arturo Hernández",
    email: "carlos.anorve@millicom.com",
  },
  user_rol: {
    resources: {
      configuration: {
        users: {
          actions: {
            grid: true,
            edit: true,
            create: true,
            delete: true,
          },
        },
        stores: {
          actions: {
            grid: true,
            qr: true,
            create: true,
            delete: true,
            details: true,
          },
        },
        bank_account: {
          actions: {
            grid: true,
            edit: true,
            create: true,
            delete: true,
          },
        },
      },
      transfers: {},
      payments: {},
      insights: {},
      home: {},
    },
  },
};

const user_metadata = (state = user, action) => {
  switch (action.type) {
    case "UPDATE_USER": {
      return {
        ...state,
        user_info: action.payload,
      };
    }
    default:
      return state;
  }
};

export default user_metadata;
