import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

const styles = {
  cell: {
    borderBottom: "none",
    paddingLeft: 0,
    paddingTop: 0,
    paddingBottom: 0,
    fontSize: 16,
  },
  title: {
    paddingBottom: 10,
  },
  table: {
    width: '100%', 
  },
};
const DataBox = ({ title, rows }) => {
  return (
    <>
      <Box>
        
        <TableContainer style={{paddingBottom:"10px"}}>
          <Table sx={styles.table}>
            <TableBody>
              {rows.map((item, index) => (
                <TableRow key={index}>
                  <TableCell
                    align="left"
                    sx={styles.cell}
                    style={{ color: "gray" }}
                  >
                    <Box style={{width:"100%"}}>
                      <Box style={{width:"30%",float:"left"}}>{item.name}</Box>  
                      <Box style={{width:"70%",float:"left",color:"#404A56",fontWeight:"500"}}>{item.value}</Box>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};
export default DataBox;
