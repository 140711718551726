import React from "react";
import { Typography } from "@mui/material";

const Title = ({ title }) => {
  return (
    <Typography className="title" justifyContent="center" textAlign="center">
      {title}
    </Typography>
  );
};

export default Title;
