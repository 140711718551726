import React from "react";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import DataBox from "./data-box";
import Grid from "@mui/material/Grid";

const RecordDetails = ({sections, config}) => {
  return (
    <>
      <Box sx={{ minWidth: 275 }}>
        <Card
          style={{
            borderRadius: "16px",
            padding: 0,
          }}
        >
          <CardContent
            sx={{
              flexDirection: "row",
              alignItems: "flex-start",
              borderRadius: "16px 0 8px 0",
            }}
          >
            <Box sx={{ width: config.width }}>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                {sections.map((element, index) => 
                  <Grid item xs={config.xs} key={index} >
                    <DataBox title={element.title} rows={element.rows} />
                  </Grid>
                )}
              </Grid>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};
export default RecordDetails;
