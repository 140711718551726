import React, { Fragment, useEffect, useState } from "react";
import  BasicTable from "../commons/basicTable";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";

export default function Transfers({ response }) {
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
      setInfo(response)
  },[response]);

  const setInfo = (response) => {
    const trans = "Transactions.";
    let col = [];
    if(response['columns']){
      for (let value of Object.values(response.columns)) {
        col.push(trans.concat(value));
      }
    }
    setColumns(col);
    setData(response.results);
  }

  const createaction = (row, i) => {
    return (
        <TableCell key={i} align="center">
            {<VisibilityIcon data-testid={`visibility-icon-${i}`} style={{ cursor: "hand" }} onClick={() => navigate("/merchants", {
              screen: "Merchants",
              state: {
                email: row.detail,
              },
            })} />}
        </TableCell>
    );
  };

  const table = (action, row, i ) => {
    return(
      <TableRow key={row.id}>
        {Object.keys(row).map((cell, index) => {
          if (index + 1 === Object.keys(row).length) {
              return action(row, i);
          } else {
              return (
                <TableCell  key={`table-${index}`} align="center">
                  {row[cell]}
                </TableCell>
              );
          }
        })}
      </TableRow>
    )
  }  

  return (
    <Fragment>
      <BasicTable
        dataTable={data}
        dataColumns={columns}
        action={createaction}
        table={table}
      />
    </Fragment>
  );
}
