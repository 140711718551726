export default function pathindex(pathname) {
  switch (pathname) {
    case "/":
      return 0;
    case "/Payments":
      return 1;
    case "/Transfer":
      return 2;
    case "/Configuration":
      return 3;
    case "/Help":
      return 4;
    default:
      return 0;
  }
}
