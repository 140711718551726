import React from "react";
import OnBoardingLayout from "../../layout/onBoarding/on-boarding-layout";
import dataJson from "../../assets/config/signIn.json";
import Title from "../../components/inputs/title"; 
import { Formik, Form } from "formik";
import { useTranslation } from "react-i18next";
import AzureAuthenticationComponent from "../../components/azure/azure-authentication-component"; 
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";


const Login = () => {
  const [t] = useTranslation("global"); 
  const navigate = useNavigate();
  const auth = useAuth();

  const onAuthenticated = (userAccountInfo) => { 
    if (userAccountInfo) { 
      auth.setAuth(userAccountInfo);
      navigate("/Insight");
    }
  };
 

  return (
    <Formik
      initialValues={{}} 
    >
      {() => (
        <Form className="formulario">
          <Title title={t(dataJson.forms.title)} />
          <AzureAuthenticationComponent  btn={dataJson.forms.actionsButtons[0]}  onAuthenticated={onAuthenticated} />
        </Form>
      )
      }
    </Formik>
    

  )
}

export default function SignIn() {
  return <OnBoardingLayout screen={<Login />} />;
}