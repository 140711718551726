import Sesion from "../../services/sesion";
import React, { useState, useEffect } from "react";
import Transfers from "../../components/insights/transfers";
import axiosClient from "../../services/axiosClient";
import { SnackBar } from "../../components/commons/alert";
import { useTranslation } from "react-i18next";
import GenericBackdrop from "../../components/commons/GenericBackdrop"; 

export default function Insight() {
  const [info, setInfo] = useState({});
  const [existData, setExistData] = useState(false);
  const [t] = useTranslation("global");
  const [loader, setLoader] = useState(false);
  const handleLoaderOpen = () => setLoader(true);
  const handleLoaderClose = () => setLoader(false); 

  const getTransfer = async () => {
    
    const url = `merchants`;
    handleLoaderOpen();
    await axiosClient
      .get(url)
      .then((response) => {
        if (response.data) {
          setInfo(response.data);
          setExistData(true)
          handleLoaderClose();
        }
      })
      .catch((err) => {
        SnackBar(t("General.Message.ErrorService"), "error");
        handleLoaderClose();
      });
  }
  useEffect(() => {
    getTransfer();
  }, []);

  return (
    <div className={"Drawer"}>
      <Sesion screen={"Merchants"} />
      <GenericBackdrop open={loader} />
      {existData &&
        <Transfers response={info} />
      }
    </div>
  );
}

