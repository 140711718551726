import React, { useEffect, useState } from "react";
import Sesion from "../../services/sesion";
import Details from "../../layout/details/Details";
import { useLocation } from "react-router-dom";
import axiosClient from "../../services/axiosClient";
import dataJson from "../../assets/config/merchants.json";
import { useTranslation } from "react-i18next";
import { transform } from "./data-utils";
import { SnackBar } from "../../components/commons/alert";

export default function Merchants() {
  const location = useLocation();
  const email = location.state ? location.state.email : "";
  const [sections, setSections] = useState([]); 
  const [title, setTitle] = useState(); 
  const [subTitle, setSubTitle]= useState(); 
  const [t] = useTranslation("global");

  const GetUser = () => {
    axiosClient.get(`user`, {
      params:{
        email: email
      }
    })
    .then(response => {
     setSections(transform(dataJson.Tabs.Proceedings, response.data, t,'Merchants.Tabs.Proceedings'));
     setTitle(response.data.Negocio["comertial-information"].organization_name);
     setSubTitle("Merchant"); 
    })
    .catch(err => {
      SnackBar(t("General.Message.ErrorService"), "error");    });
  };
  

  useEffect(() => {
    GetUser();
  }, []);
  return (
    <div className={"Drawer"}>
      <Sesion screen={"Merchants"} />
      <Details
        data-testid="details"
        title={title}
        subtitle={subTitle}
        data={{
          proceedings: {
            sections: sections,
            config: dataJson.Config,
            tabs: dataJson.Tabs.Names
          },
        }}
      />
    </div>
  );
}
