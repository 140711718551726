import { PublicClientApplication } from "@azure/msal-browser";

import { MSAL_CONFIG } from "./azure-authentication-config";

export class AzureAuthenticationContext {
  myMSALObj = new PublicClientApplication(MSAL_CONFIG);
  account;
  loginRequest;

  isAuthenticationConfigured = false;

  constructor() {
    // @ts-ignore
    this.account = null;
    this.setRequestObjects();
    if (MSAL_CONFIG?.auth?.clientId) {
      this.isAuthenticationConfigured = true;
    }
  }

  setRequestObjects = () => {
    this.loginRequest = {
      scopes: [],
      prompt: "select_account",
    };
  };

  login(signInType, setUser) {
    if (signInType === "loginPopup") {
      this.myMSALObj
        .loginPopup(this.loginRequest)
        .then((resp) => {
          this.getAccount();
          localStorage.setItem("accessToken", resp.accessToken);
          this.handleResponse(resp, setUser);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  handleResponse(response, incomingFunction) {
    if (response !== null && response.account !== null) {
      this.account = response.account;
    } else {
      this.account = this.getAccount();
    }

    if (this.account) {
      incomingFunction(this.account);
    }
  }
  getAccount(currentAccounts = this.myMSALObj.getAllAccounts()) {
    if (currentAccounts === null) {
      // @ts-ignore
      return undefined;
    }

    if (currentAccounts.length >= 1) {
      // TBD: Add choose account code here
      // @ts-ignore
      return currentAccounts[0];
    }
  }
}

export default AzureAuthenticationContext;
