import axios from "axios";

const BASE_URL = process.env.REACT_APP_KYX_BACK;
const APIKEY = process.env.REACT_APP_APIKEY;

export const axiosClient = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
    "x-api-key": APIKEY,
    Accept: "application/json",
  },
});

export default axiosClient;
