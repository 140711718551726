import React from "react";
import { Grid, Typography } from "@mui/material";

export default function OnBoardingLayout({ screen, withoutContainer = false }) {
  return (
    <div>
      {/* Header */}
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        className="head-login-container"
      >
        <Grid item xs={3} display="flex" justifyContent="center">
          <img
            src="https://www.worldremit.com/images/logos/Tigo-logo.png"
            alt="tigo money"
          />
        </Grid>
      </Grid>

      {!withoutContainer ? (
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <Grid
            item
            xl={3}
            lg={4}
            md={5}
            sm={11}
            xs={11}
            className="body-login-container"
          >
            {screen}
          </Grid>
        </Grid>
      ) : (
        screen
      )}
      {/* Body */}

      {/* Footer */}
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="space-around"
        className="footer-login-container"
      >
        <Grid
          item
          md={5}
          sm={12}
          xs={12}
          display="flex"
          justifyContent="center"
        >
          <Typography className="text">
            Todos los derechos reservados @Tigo Money
          </Typography>
        </Grid>

        <Grid
          item
          md={5}
          sm={12}
          xs={12}
          display="flex"
          justifyContent="space-evenly"
        >
          <a href="#" className="text">
            Ayuda
          </a>
          <a href="#" className="text">
            Términos y condiciones
          </a>
          <a href="#" className="text">
            Aviso de privacidad
          </a>
        </Grid>
      </Grid>
    </div>
  );
}
