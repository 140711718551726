const split_object = (directory, arr) => {
  let arr_ = directory.split(".");
  for (let x in arr_) {
    arr = arr[arr_[x]];
  }
  return arr;
};
const transform = (config, arr, t = () => {},screenName='') => {
  let structure = []
  let json = arr.Negocio
  for(let i in json){
    let row = []
    for(let j in json[i]){
      row.push({name:screenName!=''?t(screenName+'.'+j.replaceAll('-', '_')):t(j.replaceAll('-', '_')),value:json[i][j]})
    }
    structure.push({title:i,rows:row})
  }
  return structure;
};

export { split_object, transform };
