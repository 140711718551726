import React, {useRef, useEffect, useState} from "react";
import {styled} from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
    ListItemIcon,
    ListItem,
    ListItemText,
    makeStyles,
} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import PermissionsGate from "../../services/PermissionsGate";
import {useNavigate, useLocation} from "react-router-dom";
import Frametigo from "../../assets/images/Frametigo.svg";
import Logotigo from "../../assets/images/Logotigo.svg";
import HomeIcon from "@mui/icons-material/Home";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import CachedIcon from '@mui/icons-material/Cached';
import Tooltip from "@mui/material/Tooltip";
import "../../assets/styles/styles.css";
import pathindex from "../../utils/pathindex";

const drawerWidth = 240;
const openedMixin = (theme) => ({
    width: drawerWidth,
    background:
        "linear-gradient(0deg, rgba(250,190,0,1) 50%, rgba(255,216,88,1) 90%);",
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
});

const closedMixin = (theme) => ({
    background:
        "linear-gradient(0deg, rgba(250,190,0,1) 51%, rgba(255,216,88,1) 83%);",
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
        width: `calc(${theme.spacing(9)} + 1px)`,
    },
});

const DrawerHeader = styled("div")(({theme}) => ({
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    height: "132px",
    padding: theme.spacing(0, 1),
    backgroundColor: "#ffc70d",
    clipPath: "circle(68% at 52% 100%)",
    ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
})(({theme, open}) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    backgroundColor: "red",
    ...(open && {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
    }),
}));

const useStyles = makeStyles((theme) => ({
    listitem: {
        [theme.breakpoints.down("md")]: {
            marginTop: "0%",
        },
        [theme.breakpoints.up("md")]: {
            marginTop: "0%",
        },
    },
    Coloricon: {
        color: "#343C46",
        marginLeft: "5px",
    },
    Textitem: {
        fontSize: "16px",
        color: "#343C46",
        fontWeight: "bold",
        fontFamily: "Roboto",
    },
    Textitemactive: {
        fontSize: "16px",
        color: "#004FB6",
        fontWeight: "bold",
        fontFamily: "Roboto",
    },
    Borderitem: {
        borderLeft: "4px solid #004FB6",
    },
    Coloritem: {
        color: "#004FB6",
    },
}));

export default function Sidebar() {
    const [t] = useTranslation("global");
    const [open, setOpen] = React.useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    let history = useNavigate();
    const wrapperRef = useRef(null);
    useOutside(wrapperRef);
    const classes = useStyles();
    let location = useLocation();

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        setSelectedIndex(pathindex(location.pathname));
    }, []);

    const component = (index, value = "/Insight") => {
        setSelectedIndex(index);
        setOpen(false);
        history(value);
    };

    function useOutside(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    handleDrawerClose();
                }
            }

            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    const stylesitems = (index) => {
        if (selectedIndex === index) {
            return classes.Borderitem;
        }
    };

    const stylesitemsicon = (index) => {
        if (selectedIndex === index) {
            return classes.Coloritem;
        } else {
            return classes.Coloricon;
        }
    };

    return (
        [
            "/Finish",
            "/Contract",
            "/form",
            "/SignUp",
            "/SignIn",
            "/messageScreen",
        ].indexOf(location.pathname) < 0 && (
            <Box ref={wrapperRef} sx={{display: "flex"}}>
                <CssBaseline/>
                <Drawer
                    variant="permanent"
                    open={open}
                    style={{backgroundColor: "#FABE00"}}
                >
                    {open ? (
                        <div
                            style={{
                                paddingLeft: "35px",
                                paddingRight: "25px",
                                paddingTop: "15%",
                                paddingBottom: "28%",
                                clipPath: "circle(75% at 57% 0%)",
                                background: "#FABE00",
                                transition: "all .1s ease-in-out",
                            }}
                        >
                            <img
                                style={{
                                    transition: "all .3s ease-in-out",
                                    width: "160px",
                                    height: "48px",
                                }}
                                src={Frametigo}
                                alt="Tigo"
                            ></img>
                        </div>
                    ) : (
                        <div
                            style={{
                                paddingLeft: "10px",
                                paddingRight: "20px",
                                paddingTop: "30%",
                                paddingBottom: "120%",
                                clipPath: "circle(100% at 150% 6%)",
                                transition: "all .3s ease-in-out",
                                background: "#FABE00",
                            }}
                        >
                            <img
                                style={{
                                    width: "48px",
                                    height: "48px",
                                    transition: "all .1s ease-in-out",
                                }}
                                src={Logotigo}
                                alt="LogoTigo"
                            ></img>
                        </div>
                    )}
                    <List>
                        <PermissionsGate
                            RenderError={() => <p>You not pass!</p>}
                            scopes={"resources.insights"}
                        >
                            <ListItem
                                className={`logotigocss ${stylesitems(0)}`}
                                button
                                key="Insights"
                                onClick={() => component(0, "/Insight")}
                            >
                                <ListItemIcon>
                                    <Tooltip title={t("Menu.Merchants")}>
                                        <HomeIcon className={stylesitemsicon(0)}/>
                                    </Tooltip>
                                </ListItemIcon>
                                <ListItemText
                                    classes={
                                        selectedIndex === 0
                                            ? {primary: classes.Textitemactive}
                                            : {primary: classes.Textitem}
                                    }
                                    primary={t("Menu.Merchants")}
                                />
                            </ListItem>
                            <ListItem
                                className={`logotigocss ${stylesitems(1)}`}
                                button
                                key="Operations"
                                onClick={() => component(1, "/Operations")}
                            >
                                <ListItemIcon>
                                    <Tooltip title={t("Menu.Operations")}>
                                        <CachedIcon className={stylesitemsicon(1)}/>
                                    </Tooltip>
                                </ListItemIcon>
                                <ListItemText
                                    classes={
                                        selectedIndex === 1
                                            ? {primary: classes.Textitemactive}
                                            : {primary: classes.Textitem}
                                    }
                                    primary={t("Menu.Operations")}
                                />
                            </ListItem>
                        </PermissionsGate>

                    </List>
                    {open ? (
                        <Divider
                            sx={{
                                borderBottomWidth: 1,
                                marginLeft: "10%",
                                marginRight: "10%",
                                marginTop: "5%",
                                background: "#343C46",
                            }}
                        />
                    ) : (
                        <Divider
                            sx={{
                                borderBottomWidth: 2,
                                width: "24px",
                                marginLeft: "30%",
                                marginTop: "5%",
                                background: "#343C46",
                            }}
                        />
                    )}
                    <List style={{height: "inherit", display:"none"}}>
                        <PermissionsGate
                            RenderError={() => <p>You not pass!</p>}
                            scopes={"resources.configuration"}
                        >
                            <ListItem
                                className={`logotigocss ${stylesitems(3)}`}
                                button
                                key="Insights"
                                onClick={() => component(3, "/Configuration")}
                            >
                                <ListItemIcon>
                                    <Tooltip title={t("Menu.Configuration")}>
                                        <SettingsOutlinedIcon className={stylesitemsicon(3)}/>
                                    </Tooltip>
                                </ListItemIcon>
                                <ListItemText
                                    classes={
                                        selectedIndex === 3
                                            ? {primary: classes.Textitemactive}
                                            : {primary: classes.Textitem}
                                    }
                                    primary={t("Menu.Configuration")}
                                />
                            </ListItem>
                        </PermissionsGate>
                        <ListItem
                            className={`logotigocss ${stylesitems(4)}`}
                            button
                            key="Insights"
                            onClick={() => component(4, "/Help")}
                        >
                            <ListItemIcon>
                                <Tooltip title={t("Menu.Help")}>
                                    <HelpOutlineOutlinedIcon className={stylesitemsicon(4)}/>
                                </Tooltip>
                            </ListItemIcon>
                            <ListItemText
                                classes={
                                    selectedIndex === 4
                                        ? {primary: classes.Textitemactive}
                                        : {primary: classes.Textitem}
                                }
                                primary={t("Menu.Help")}
                            />
                        </ListItem>
                    </List>
                    <div>
                        <DrawerHeader>
                            {open ? (
                                <IconButton
                                    data-testid={t("Menu.Hide")}
                                    onClick={handleDrawerClose}
                                >
                                    <Tooltip title={t("Menu.Hide")}>
                                        <ChevronLeftIcon style={{fontSize: 40}}/>
                                    </Tooltip>
                                </IconButton>
                            ) : (
                                <IconButton
                                    data-testid={t("Menu.Open")}
                                    onClick={handleDrawerOpen}
                                >
                                    <Tooltip title={t("Menu.Open")}>
                                        <ChevronRightIcon style={{fontSize: 40}}/>
                                    </Tooltip>
                                </IconButton>
                            )}
                        </DrawerHeader>
                    </div>
                </Drawer>
            </Box>
        )
    );
}
