import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";

const getNavigate = (auth, location) => {
  if (auth?.idTokenClaims) return <Outlet />;

  return <Navigate to="/SignIn" state={{ from: location }} replace />;
};

const RequireAuth = () => {
  const { auth } = useAuth();
  const location = useLocation();
  return getNavigate(auth, location);
};

export default RequireAuth;
