import React from "react";
import Card from "@mui/material/Card";
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

const SimpleCard = ({ title, subtitle }) => {
  return (
    <>
      <Box sx={{ minWidth: 275 }}>
        <Card
          style={{
            height: "112px",
            borderRadius: "16px",
            padding: 0,
          }}
        >
          <CardContent
            sx={{
              flexDirection: "row",
              alignItems: "flex-start",
              position: "absolute",
              borderRadius: "16px 0 8px 0",
            }}
          >
            <CardContent>
              <Typography variant="h5" style={{fontWeight:"500",fontFamily:"Roboto",fontSize:"18px"}} component="div">
                {title}
              </Typography>
              <Typography sx={{ mb: 1.5 }} color="text.secondary">
                {subtitle}
              </Typography>
            </CardContent>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

export default SimpleCard;
